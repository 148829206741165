.button {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border: none;
  outline: none;
  user-select: none;
  font-weight: 700;
  font-size: 0.8735rem;
  line-height: 1rem;
  color: #111522;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  white-space: nowrap;
  background: transparent;
  text-transform: uppercase;

  &:active {
    transform: translateY(2px);
  }

  &_borderRadius {
    &_none {
      border-radius: 0;
    }
    &_default {
      border-radius: 0.25rem;
    }
    &_15 {
      border-radius: 0.9375rem;
    }
    &_5 {
      border-radius: 0.3125rem;
    }
  }
  &_padding {
    &_default {
      padding: 0 1.25rem;
    }
  }

  &_color {
    &_default {

      border-radius: 0.625rem;
      background: var(--Accent-Accent, #E41420);
      box-shadow: none;
      color: #fff;
    }
    &_pale {
      background: #FFFFFF;
      opacity: 0.3;
      color: #fff;
    }
    &_grey {
      background: #505050;
      color: #fff;
    }
    &_bordered {
      color: #ff0000;
      border: 1px solid #ff0000;
    }
    &_transparent {
      color: #fff;
    }
    &_change-lang {
      background: var(--Accent-Accent, #E41420);
      box-shadow: 0px 0px 10px 0px #F44;
      color: #fff;
    }
    &_gray {
      background: var(--Light-UI-Cards-2, #F1F3F7);
      border-radius: 0.625rem;
      color: #222222;
    }
    &_transparent {
      border-radius: 0.625rem;
      border: 2px solid var(--Accent-Accent, #EA2326);
      color: var(--Light-UI-Text-Regular-1, #222);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
    &_withShadow {
      background: var(--Accent-Accent, #E41420);
      box-shadow: 0px 0px 10px 0px #F44;
      color: #fff;
      border-radius: 0.625rem;
    }

    &_grey-border {
      background: transparent;
      color: black;
      border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
      border-radius: 0.625rem;
      text-transform: none;
    }

    &_default-normal {
      text-transform: none;
      border-radius: 0.625rem;
      background: var(--Accent-Accent, #E41420);
      box-shadow: none;
      color: #fff;
    }
    
  }
  &:hover {
  }
  &:hover#{&}_color {
    &_default {
    }
  }

  &_disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: #E41420;
  }
}
