.toggle {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &__input {
    display: none;
  }

  &__slider {
    width: 28px;
    height: 12px;
    background: #AFAFAF;
    border-radius: 12px;
    position: relative;
    transition: background 0.3s;
  }

  &__circle {
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 2px solid #c4c4c4;
    border-radius: 50%;
    position: absolute;
    top: -2px;
    transition: transform 0.3s, background 0.3s, width 0.3s, height 0.3s;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__input:checked + &__slider {
    background: #ea2326;
    border-radius: 15px;
  }

  &__input:checked + &__slider .toggle__circle {
    transform: translateX(14px);
    background: #ffffff;
    border: none;
    border: 2px solid #ea2326;
  }

  &__input:checked + &__slider .toggle__circle-inner {
    background: white;
  }
}
