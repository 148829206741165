
.active-deposit-receipt-modal{
  box-sizing: border-box;
  background: transparent;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background: #00000066;

  &__content {
    // max-width: 23.571rem;
    max-height: 80dvh;
    padding: 1.875rem;
    border-radius: 1.25rem;
    border: 1px solid #DDD;
    background: var(--Light-UI-Text-Regular, #FFF);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.30);
    color: #000;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__image {
    margin-top: 1rem;
    
    img {
      min-height: 70dvh;
      max-width: 70dvw;
      max-height: 80dvh;
    }
  }

  &__cross {
    cursor: pointer;
    padding: 0.5rem;
    svg {
      width: 0.875rem;
      height: 0.875rem;
    }
  }
}