.input-photo-desktop {
  &:not(_withIcon) {
    user-select: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    color: black;
    cursor: pointer;
  }

  &_withIcon {
    box-sizing: border-box;
    padding: 0.5rem 0.625rem 0.5rem 1.25rem;
    user-select: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
    border-radius: 0.625rem;
  }

  &__native {
    display: none;
  }
  &__placeholder {
    color: var(--Dark-UI-Text-Additional-1, #959595);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__name {
    color: var(--primaryTextColor);
    font-size: 0.875rem;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__error {
    color: #ff0000;
  }

  &__icon {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
}
