.active-deposit-request-item {
  padding: 0.625rem 1.25rem;
  border-radius: 0.625rem;
  border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
  background: var(--Light-UI-Cards-1, #FFF);
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 0.625rem;

  font-size: 0.857rem;
  font-weight: 700;
  color: #000;

  &__top, &__bottom {
    display: flex;
    justify-content: space-between;
  }

  &__bottom {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0.625rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-text {
      color: var(--Light-UI-Text-Additional-1, #828282);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &-value {
      overflow: hidden;
      color: var(--Light-UI-Text-Regular-1, #222);
      font-variant-numeric: lining-nums proportional-nums;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &-receipt {
        cursor: pointer;
        display: flex; 
        align-items: center;
        gap: 0.3125rem;
        padding: 0.125rem 0.9375rem 0.125rem 0.625rem;
        height: 100%;

        border-radius: 0.625rem;
        background: var(--Light-UI-Cards-2, #F1F3F7);

        overflow: hidden;
        color: var(--Dark-UI-Text-Regular, #000);
        text-align: center;
        text-overflow: ellipsis;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      &_red {
        span {
          color: var(--Accent-Accent, #EA2326);
          text-align: center;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 0.625rem;
    justify-content: flex-end;
  }

  &__button {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-text {
      color: var(--Light-UI-Text-Regular, #FFF);
      font-family: Arial;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;

      &_red {
        color: #EA2326;
      }
    }
  }

  &__center {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
      button {
        height: 1.938rem;
        width: 100%;
      }
  }

  &__noReceipt {
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}