.active-deposit-request-modal {
  box-sizing: border-box;
  padding: 8.5rem 1.071rem 0.714rem;
  background: transparent;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  background: #00000066;

  & * {
    box-sizing: border-box;
  }

  &__content {
    width: 100%;
    max-width: 23.571rem;
    padding: 1.875rem;
    border-radius: 1.25rem;
    border: 1px solid #DDD;
    background: var(--Light-UI-Text-Regular, #FFF);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.30);
    color: #000;
  }

  &__header {
    margin-bottom: 0.714rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  &__cross {
    width: 1.142rem;
    height: 1.142rem;
    cursor: pointer;
  }

  &__reject {
    margin-bottom: 0.625rem;
    width: 72.6%;
    color: #000;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__amount {
    margin-left: 0.25rem;
    color: #E41420;
  }

  &__comment {
    margin-bottom: 0.25rem;
    color: #000;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__note {
    width: 100%;
    margin-bottom: 1.429rem;
    padding: 0.714rem;
    border: 1px solid #848484;
    font-family: 'Roboto', sans-serif;
    font-size: 0.857rem;
    font-weight: 700;
    border-radius: 0.625rem;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);

    &::placeholder {
      color: var(--Light-UI-Text-Additional-1, #828282);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &__button {
    height: 2.571rem;
  }

  &__buttons {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.1375rem;
  }

  &__required {
    color: red;
    margin-left: 0.125rem;
  }
}