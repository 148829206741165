.payment-methods-item {
  display: flex;
  padding: 0.625rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 0.625rem;

  border-radius: 0.625rem;
  border: 1px solid var(--Accent-Accent, #EA2326);

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info {
    margin-left: 0.63rem;
  }

  &__logo {
    height: 1.5rem;
    width: 1.5rem;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: var(--Dark-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__circle {
    position: absolute;
    left: 0.31rem;

    color: #1E9E5C;
  }

  &__status {
    padding-left: 1rem;
    padding-bottom: 0.12rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--Status-Good, #1E9E5C);
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &_active {
      color: #1E9E5C;
    }
  }

  &__bottom {
    color: var(--Dark-UI-Text-Additional-1, #959595);
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__arrow {
    width: 1.9375rem;
    height: 1.875rem;
    cursor: pointer;
  }
}