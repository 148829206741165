.payment-methods {
  color: black;
  height: 100%;

  &__title {
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 500;
  }

  &__title-2 {
    font-family: Poppins;
    font-size: 1.875rem;
    font-weight: 500;
  }

  &__container {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 1.87rem;
    gap: 1.25rem;
    width: 100%;
    height: 100%;
  }

  &__block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
  }

  &__left-block,
  &__right-block {
    flex: 1;
    border-radius: 1.25rem;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
    padding: 1.88rem 2.5rem;
    margin-bottom: 5.5rem;
  }

  &__right-block &__items-container {
    padding: 0.65rem;
  }
  &__right-block &__block-header {
    justify-content: center;
  }

  &__button {
    display: flex;
    padding: 0.625rem 0.9375rem 0.625rem 0.625rem;
    justify-content: center;
    align-items: center;
    align-self: stretch; 
    height: 48px;

    &-icon {
      height: 1.5rem;
      width: 1.5rem;
    }

    &-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--New-Dark-UI-Text-Regular, #FFF);
      text-align: center;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  &__null-descr {
    display: flex;
    margin: 0 auto;
    padding-top: 5.66rem;
    width: 15.25rem;

    text-align: center;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__form {

    &-title {
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &-header {
      display: flex;
      justify-content: space-between;
    }

    &-button {
      height: 2.5rem;
      flex: 1;
      margin-top: 0.63rem;
    }

    &-inputs {
      margin-top: 0.91rem;
      width: 100%;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0.62rem;
    margin-top: 0.63rem;
  }

  &__items-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 0.62rem;
  }

  &__item {
    width: 100%;
    &-title {
      color: var(--Dark-UI-Text-Regular, #000);
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      margin-bottom: 0.2rem;
    }
  }

  &__search-block{
    height: 2.5rem;
    min-width: 10rem;
    max-width: 100%;

    /* Убираем стрелочки увеличения/уменьшения в браузере Chrome, Safari и Opera */
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Убираем стрелочки увеличения/уменьшения в браузере Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    
    .input-photo-desktop {

      &__name {
        max-width: 20rem;
      }
    }
  }

  &__items-container {
    display: flex;
    flex-direction: column;
    gap: 0.63rem;
    overflow-y: auto;
    max-height: 72dvh;
    padding-right: 0.125rem;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
      background: transparent; 
      border-top: 0.1rem solid transparent;
      border-bottom: 0.1rem solid transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #C4C6C9; 
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: darken(#C4C6C9, 10%); 
    }
  }

  &__required {
    color: red;
  }
}
